import React from 'react';
import { Accordion, Card } from "react-bootstrap";
import { Url } from "_constants";

interface IFaq {
  question: string;
  answer: any;
}

const Faq: React.FunctionComponent = () => {
  const questions: IFaq[] = [
    {
      question: "Why should I get screened for colon cancer?",
      answer: (
        <>
          <p>Colon cancer is the second leading cause of cancer death in the US. One in 24 Americans will be diagnosed with colon cancer. Fortunately, if diagnosed early, over 90% of patients will have a good outcome with surgery alone. But delaying diagnosis allows the cancer to spread to nearby lymph nodes and tissues, requiring chemo therapy and resulting in a significant drop in survival rates. This is why Convena Health is focusing on early detection. </p>
          <p>The American College of Gastroenterology and the American Cancer Society recommend that people with no bowel disease, and no family or personal history of colon cancer start screening at 45 years of age with an annual FIT test, and colonoscopy every 10 years. Please GET SCREENED as soon as possible and do not put it off. Your life may actually depend on it. </p>
        </>
      ),
    },
    {
      question: "What are symptoms of colon cancer?",
      answer: <p>Many people do not have any symptoms at all until colon cancer has advanced.  That's why routine screening is so important. As disease progresses, patients may see blood in the stool, have abdominal discomfort, smaller stools, or constipation. Other vague symptoms include fatigue and increasing shortness of breath with exertion. </p>,
    },
    {
      question: "What is a FIT test?",
      answer: <>
        <p>FIT stands for Fecal Immunochemical Test. This relatively new test is used to detect human blood in stool that cannot be seen with the naked eye.  A FIT is often used to detect bleeding in the digestive tract which has no other signs or symptoms. A FIT test doesn’t require a restricted diet before. A FIT test may not detect blood from further up the digestive tract (such as the stomach), which means it is more specific to finding blood coming from the lower gastrointestinal tract than prior less accurate tests.</p>

        Important benefits of FIT:
        <ul>
        <li>Examines stool for signs of blood</li>
        <li>No liquids or prep to drink</li>
        <li>No risks</li>
        <li>Inexpensive</li>
        <li>Easy to do</li>
        <li>No special changes to diets or medicines</li>
        <li>Simple; not as many stool samples needed</li>
        <li>Done in privacy of your home and flexible for your schedule</li>
        </ul>

      </>,
    },
    {
      question: "How accurate is this FIT test?",
      answer: <>
        <p>Convena Health uses a FIT test made by the leading brand of stool testing, Beckman Coulter, trusted by American physicians for decades. It is one of the very few at-home immunoassay stool tests that has been extensively evaluated in published clinical trials. This FIT test has been shown in clinical studies to correctly identify over 98% of stool samples containing human blood. More importantly,  it accurately detected 80% to 90% of confirmed colon cancers in various studies. Stool tests are not as good at detecting pre-cancerous polyps, however, only detecting about 30% to 40%. While a negative FIT result makes colon cancer much less likely, it does not completely rule it out and it is not intended to replace colonoscopy.</p>
        <p>A positive FIT test indicates human blood in the lower GI tract. These tests typically have less than a 1% false positive rate for any GI disease. not necessarily cancer. In addition to colon cancer and polyps, a positive FIT can be caused by many other conditions, including hemorrhoids, ulcers, anal fissures, diverticular disease, or inflammation. And, like any test, FIT may have an abnormal result even though there is nothing wrong.  In almost all cases, a colonoscopy is recommended to further evaluate a positive FIT result. Make sure to share your results with your physician.</p>

      </>,
    },
    {
      question: "How do I perform an at-home FIT test?",
      answer: <>
      <p>It's very quick and easy to collect a stool FIT sample at home. You can view and print detailed step by step instructions <a href="/files/web-instructions-sheet.pdf" target="_blank">here</a>. </p>

      <p>You can also <a href={Url.CUSTOMER_VIDEO_PAGE}>watch this video</a>.</p>

      <p>Once you've activated your test card and collected your stool sample, just mail it back to us via the enclosed medical specimen envelope the next day.</p>
      </>,
    },
    {
      question: "How long does it take to get my results?",
      answer: <p>We will typically have your results ready within 5 days of receiving your sample. You will get an email notifying you to login to your account at ConvenaHealth.com to view your results. Remember to check your email sample folder for the email as well as check your account frequently. Your results will be accompanied by an explanation and any further recommendations for follow up care. Any positive result should be shared with your healthcare provider and followed up with a colonoscopy.</p>
    },
    {
      question: "How do you handle returns or order cancellations?",
      answer: <p>You can cancel your order at any time prior to shipping. However, due to the medical nature of the FIT, we cannot accept any returns after shipping. To cancel your order, simply enter your online account, locate your order and click on Cancel.</p>,
    },
  ];

  return (
    <div className="faq">
      <Accordion>
        {questions.map((q: IFaq, i: number) => (
          <Card key={`card-${i}`}>
            <Accordion.Toggle as={Card.Header} className="small-text-4 primary-dark" eventKey={i.toString()}>
              {q.question}
            </Accordion.Toggle>

            <Accordion.Collapse as={Card.Header} eventKey={i.toString()}>
              <Card.Body className="small-text-2">{q.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  );
};

export {Faq}