import React from "react";
import { HashLink } from "react-router-hash-link";
import { Url } from "_constants";

const Footer: React.FunctionComponent = () => {
  return (
    <div className="container-fluid mt-5 mb-0 footer">
      <div className="row p-5">
        <div className="col-auto col-lg-4 small-text-2 px-lg-5">
          <div className="footer-img mb-3">
            <img src="/images/footer-logo__white.png" alt="Convena Health" />
          </div>
          <p>The easy and convenient way to get screened annually for Colon Cancer.</p>
          <p>Screens for the presence of blood in your stool to help detect early signs of colon cancer.</p>
        </div>

        <div className="col-auto col-lg-2">
          <div className="footer-link-header">All Links</div>
          <div className="footer-link-item">
            <a href="/">Home</a>
          </div>
          <div className="footer-link-item">
            <HashLink
              className="footer-link-item"
              scroll={(el: any) => el.scrollIntoView({ behavior: "smooth" })}
              to={"/#pricing"}
            >
              Pricing
            </HashLink>
          </div>
          <div className="footer-link-item">
            <HashLink className="footer-link-item" scroll={(el: any) => el.scrollIntoView({ behavior: "smooth" })} to={"/#faq"}>
              FAQ
            </HashLink>
          </div>
          <div className="footer-link-item">
            <a href={Url.CUSTOMER_PRIVACY_POLICY}>Privacy Policy</a>
          </div>
          <div className="footer-link-item">
            <a href={Url.CUSTOMER_TERMS}>Terms and Conditions</a>
          </div>


        </div>

        <div className="col-auto col-lg-3 ">
          <div className="footer-link-header">Contact Us</div>
          <div className="footer-link-item">contact@convenahealth.com.com</div>

          <div className="footer-link-item">
            92 East Main Street
            <br />
            Suite 405
            <br />
            Somerville, NJ 08844
          </div>
        </div>

        <div className="col-auto col-lg-3 ">
          <div className="footer-map">
            <img src="/images/footer-map.png" alt="Convena Health" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
