import React from 'react';
import { useHistory } from 'react-router';
import { Envelope, AcgRecommendsSection, CertifiedLabSection, DigitalResultsSection, FaqSection, HowItWorksSection, PreventableSection } from '_components/HomePage';
import { Url } from '_constants';


const Jumbotron: React.FunctionComponent<{ onClick: any }> = ({ onClick }) => {
  return (
    <div className="row jumbotron">
      <div className="col-auto col-md-6 px-lg-5">
        <div className="mb-4 text-end text-md-start text-lg-start text-xl-start">
          <span className="recommended small-text-4">Physician Recommended</span>
        </div>
        <p className="title text-center text-md-start text-lg-start">
          <span className="dark-blue strong">Home</span> <span className="light-blue strong">Colon Cancer</span>{" "}
          <span className="dark-blue strong">FIT</span> <span className="dark-blue">Screening Test</span>
        </p>

        <div className="d-xs-inline-block d-sm-inline-block d-md-none d-lg-none d-xl-none mb-2">
          <Envelope />
        </div>

        <p id="pricing" className="subtitle dark-gray strong text-center text-md-start text-lg-start text-xl-start">
          The easy and convenient way to get screened annually for Colon Cancer.
        </p>
        <div className="container-auto">
          <div className="row">
            <div className="col-12 col-md-6 text-xs-center text-center text-md-start text-lg-start text-xl-start">
              <div className="subtitle dark-blue strong">Get Tested Now!</div>
              <div className="small-text-5 light-blue strong my-2">Only $39!</div>
              <button className="btn btn-cta small-text-5 my-3" onClick={onClick}>
                Activate Your Kit
              </button>
            </div>
          </div>

        </div>
      </div>
      <div className="col-auto col-md-6 panel-2 px-lg-5">
        <div className="rounded-box small-text-4 mx-3 text-center text-md-start text-lg-start text-xl-start">
          <span className="dark-blue">Colon Cancer is</span> <span className="light-blue">America’s #2</span>{" "}
          <span className="dark-blue">cause of cancer death. Early Diagnosis is the key to a good outcome.</span>
        </div>

        <div className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">
          <Envelope />
        </div>
      </div>
    </div>
  );
};

const BottomActivationSection: React.FunctionComponent<{ onClick: any }> = ({ onClick }) => {
  return (
    <div className="row bottom-activation-section py-3 p-lg-5">
      <div className="col p-lg-5 text-center text-md-start text-lg-start text-xl-start">
        <div className="container">
          <div className="row">
            <div className="col-auto col-lg-6">
              <div className="title strong dark-blue">
                At-home <span className="light-blue strong">Colon Cancer</span> Screening Test
              </div>
              <p className="small-text-3 dark-gray">
                Screens for the presence of blood in your stool to help detect early signs of colon cancer
              </p>

              <button className="btn btn-cta small-text-5" onClick={onClick}>
                Activate Your Kit
              </button>
            </div>

            <div className="col-auto col-lg-6 mt-3">
              <div className="envelope">
                <img src="/images/Envelop.png" alt="Convena Health Test Kit" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColonPage = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(Url.CUSTOMER_ACTIVATION);
  }

  return(
    <div className="my-2">

      <Jumbotron onClick={onClick} />

      <HowItWorksSection />

      <AcgRecommendsSection />

      <PreventableSection />

      <CertifiedLabSection />

      <DigitalResultsSection />

      <FaqSection />

      <BottomActivationSection onClick={onClick}/>

    </div>
  );
}

export { ColonPage }