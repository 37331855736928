import React from 'react';

const PrivacyPolicy = () => {
  return(
<div className="privacy-policy container">

<h1 className="text-center">Privacy Policy</h1>

<p>Protecting your private information is our priority. This Statement of Privacy applies to www.ConvenaHealth.com, and Convena Health, Inc., Convena advertising and applications on third party websites, and our Services, including the Health Consultants and Labs, and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Convena Health, Inc. include www.ConvenaHealth.com and the above mentioned Convena applications. The Convena website is an Online Health Products site. By using the Convena website, you consent to the data practices described in this statement.</p>

<p className="fw-bold">Collection of your Personal Information</p>

<p>In order to better provide you with products and services offered, Convena may collect personally identifiable information, such as your First and Last Name, Mailing Address, E-mail Address, Phone Number, Date of Birth and or any other information defined as “Protected Health Information” under HIPAA laws and regulations.
If you purchase Convena's products and services, we collect billing and credit card information. This information is used to complete the purchase transaction.
Convena may also collect anonymous demographic information, which is not unique to you, such as your age, gender, race.</p>

<p>The types of personal information that we may collect include, but are not limited to: the information you provide to us, information collected automatically about your use of our Site, information from third parties, including our business partners.
You may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services; (f) completing a survey. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>

<p className="fw-bold">Use of your Personal Information </p>
<p>Convena collects and uses your personal information to operate and deliver the services you have requested.</p>

Convena may also use your personally identifiable information to inform you of other products or services available from Convena and its affiliates.

<p className="fw-bold">Sharing Information with Third Parties</p>

<p>Convena does not sell, rent or lease its customer lists to third parties.</p>
<p>Convena may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Convena, and they are required to maintain the confidentiality of your information.</p>
<p>If you choose to utilize our Services, you will collect the appropriate biological sample(s) using a sample collection kit.  The sample and your information will be provided to a Lab for the purpose of providing the Services.  The Lab will store your test results and related data as required by applicable laws, regulations, and College of American Pathologists (“CAP”) accreditation standards. Samples and test related data may be used or stored for regulatory compliance or healthcare operations, and de-identified for internal quality control, validation studies, and internal research and development purposes.</p>
<p>Convena may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Convena or the site; (b) protect and defend the rights or property of Convena; and/or (c) act under exigent circumstances to protect the personal safety of users of Convena, or the public.</p>
<p>Convena may disclose your information to the Lab(s) and Health Consultants involved in providing you Services.  Upon your request, we may disclose your information to others involved in your care, including your healthcare providers, the health system or clinic where your provider practices, and other providers that you designate to receive your information.</p>
<p>Convena may use your de-identified information and test results in our research. We may engage in research with third parties like universities, hospitals, health systems, government institutions, or private companies to develop new tests, validate technologies, or improve existing technologies or processes. You can opt out of such third party research by contacting us directly at privacy@convenahealth.com . However, if you have consented in the past and later opt out, we cannot retract your de-identified information, and/or results from research already performed. We may author publications using de-identified information, either on our own or in collaboration with academic or commercial third parties.</p>
<p>We may share aggregated, de-identified information (for example, aggregated trends about the general use of our Services) publicly and with our affiliates, subsidiaries, and partners. This information will not include medical information.</p>
<p>We may share your information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by us about our Site users and Members is among the assets transferred, and you agree to and do hereby consent to our assignment or transfer of rights to your personal information.</p>

<p className="fw-bold">Tracking User Behavior</p>
<p>Convena may keep track of the websites and pages our users visit within Convena, in order to determine what Convena services are the most popular. This data is used to deliver customized content and advertising within Convena to customers whose behavior indicates that they are interested in a particular subject area.</p>

<p className="fw-bold">Automatically Collected Information</p>
Information about your computer hardware and software may be automatically collected by Convena. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Convena website.

<p className="fw-bold">Use of Cookies</p>
<p>The Convena website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>

<p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Convena pages, or register with Convena site or services, a cookie helps Convena to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Convena website, the information you previously provided can be retrieved, so you can easily use the Convena features that you customized.</p>

<p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Convena services or websites you visit.</p>

<p className="fw-bold">Links</p>
<p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

<p className="fw-bold">Security of your Personal Information</p>
<p>Convena secures your personal information from unauthorized access, use, or disclosure. Convena uses SSL Protocol and Encryption for this purpose.</p>
<p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
<p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>

<p className="fw-bold">Right to Deletion</p>

<p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:

<ul>

<li>Delete your personal information from our records; and</li>
<li>Direct any service providers to delete your personal information from their records.</li>
</ul>
</p>

<p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:

<ul>
<li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</li>
<li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
<li>Debug to identify and repair errors that impair existing intended functionality;</li>
<li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
<li>Comply with the California Electronic Communications Privacy Act;</li>
<li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
<li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</li>
<li>Comply with an existing legal obligation; or</li>
<li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
</ul>
</p>

<p className="fw-bold">Children Under Thirteen</p>
Convena does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.

<p className="fw-bold">E-mail Communications</p>
From time to time, Convena may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from Convena or click on a link therein.

If you would like to stop receiving marketing or promotional communications via email from Convena, you may opt out of such communications by clicking on the Unsubscribe button.

<p className="fw-bold">External Data Storage Sites</p>
We may store your data on servers provided by third party hosting vendors with whom we have contracted.

<p className="fw-bold">Changes to this Statement</p>
<p>Convena reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>


<p className="fw-bold">Miscellaneous</p>
<p>Class Action Waiver. Any proceedings to resolve or litigate any dispute in any forum will be conducted solely on an individual basis. Neither you nor we will seek to have any dispute heard as a collective or class action or in any other proceeding in which either party acts or proposes to act in a representative capacity, and each party hereby waives any right to assert consolidated claims with respect to any disputes subject to arbitration under the Agreement. No arbitration or proceeding will be combined with another without the prior written consent of all parties to all affected arbitrations or proceedings.</p>
<p>Limitation of Time to File Claims. Any action, claim or dispute you have against us must be filed within one year, unless prohibited by applicable law. To the extent permitted by law, any claim or dispute under this agreement must be filed within one year in an arbitration proceeding. The one-year period begins when the claim or notice of dispute first could be filed. If a claim or dispute is not filed within one year, it is permanently barred.</p>

<p className="fw-bold">Contact Information</p>
<p>Convena welcomes your questions or comments regarding this Statement of Privacy. If you believe that Convena has not adhered to this Statement, please contact Convena at:</p>

<p>Convena Health, Inc.<br/>
92 E. Main St. Suite 405<br/>
Somerville, New Jersey 08876<br/><br/>

Email Address:<br/>
contact@convenahealth.com<br/>

Telephone number:<br/>
_________________<br/>

Effective as of October 01, 2021<br/>
</p>

</div>



  );
}

export { PrivacyPolicy }